.qb-connect {
  background: url('../assets/connect-button.png') no-repeat;
  background-size: contain;
  max-width: calc(585px / 2);
  max-width: calc(585px / 2);
  height: calc(102px / 2);
  height: calc(102px / 2);
  transition: 0.3s;
  cursor: pointer;
  opacity: 0.9;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 25px;
}

.qb-connect:hover {
  opacity: 1;
}
