ion-progress-bar {
  height: 1px !important;
}

ion-button {
  --box-shadow: initial;
}

ion-list {
  padding-top: unset !important;
  padding-bottom: unset !important;
  margin-bottom: unset !important;
}

ion-input input {
  font-size: 20px !important;
}
/* Chrome, Safari, Edge, Opera */
ion-input input::-webkit-outer-spin-button,
ion-input input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
ion-input input[type='number'] {
  -moz-appearance: textfield !important;
}

h2 {
  font-weight: bold !important;
}

ion-select {
  max-width: calc(100% - 90px);
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.no-max-width {
  max-width: unset !important;
}

.full-width {
  width: 100%;
}

.absolute-position {
  position: absolute;
}

.fixed-text-height {
  height: 22px;
}
