:root {
  --inner-width: 600px;
}

.sign-field {
  --width: 100%;
  max-width: var(--inner-width);
  margin: auto;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
}

.sign-field .popover-content {
  left: 0 !important;
}

.sign-field .popover-viewport {
  padding: 10px;
}

#sign-pad ion-col {
  height: 50px;
}

.sign-canvas {
  max-width: calc(var(--inner-width) - 50px);
  margin: auto;
}
