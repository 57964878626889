@media (min-width: 600px) {
  ion-app {
    border-left: solid 1px lightgray;
    border-right: solid 1px lightgray;
  }
}

.item-icon {
  padding-right: 5px;
}

ion-app {
  max-width: 600px;
  margin: auto;
}

.full-height {
  height: 100%;
}

.hidden {
  visibility: hidden;
}

/** fix popover error in chrome 114 */
[popover].popover-viewport {
  display: initial;
  position: initial;
  margin: initial;
  background: initial;
  width: initial;
  height: initial;
  overflow: initial;
  inset: initial;
  color: initial;
}
